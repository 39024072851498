import React, { Component } from 'react';
import './navbartabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEdit, faTrashAlt, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons';
import NavbarTabForm from './NavbarTabForm';
import DnDNavbarTabs from './DnDNavbarTabs';
import ComponentFormPopup from '../../../ComponentForm/ComponentFormPopup';

export class NavbarTabsManagement extends Component {
    static displayName = NavbarTabsManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            navbarTabs: [],
            popup: null,
            reset: false
        };
        this.displayPopup = this.displayPopup.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.deleteNavbarTab = this.deleteNavbarTab.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    displayPopup = (navbarTabId, formType) => {
        let tabForm = <NavbarTabForm
            componentId={navbarTabId}
            formType={formType}
            onComponentSaved={this.onTabSaved.bind(this)}
        />;

        let popup = <ComponentFormPopup
            popupForm={tabForm}
            cancelClicked={this.cancelClicked.bind(this)}
        />;

        this.setState({
            popup: popup
        });
    }

    onTabSaved() {
        alert("Saved");

        this.setState({
            reset: true,
            popup: null
        });
        this.getComponentData();
    }

    cancelClicked() {
        this.setState({
            popup: null
        });
    }

    async navbarTabOrderUpdate(componentArray) {
        let componentIdsOrdered = [];
        for (var i = 0; i < componentArray.length; i++) {
            componentIdsOrdered.push(parseInt(componentArray[i].id));
        }

        const response = await fetch('Admin/UpdateNavbarTabOrder', {
            method: 'POST',
            body: JSON.stringify({ "navbarTabIds": componentIdsOrdered }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != "Saved") {
            alert(data);
        }
    }

    async deleteNavbarTab(navbarTabId) {
        let confirmDelete = window.confirm("Are you sure you want to delete this tab and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteNavbarTab?componentId=' + navbarTabId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.setState({
                    reset: true
                });
                this.getComponentData();
            }
            else {
                alert(data);
            }
        }
    }

    render() {
        let tabButtons = [];
        for (var i = 0; i < this.state.navbarTabs.length; i++) {
            let navTab = this.state.navbarTabs[i];

            let navTabKey = "navbarTab" + i;

            let currentComponentManagementBtns =
                <>
                    <span className='current-component__edit' onClick={this.displayPopup.bind(this, navTab.id, 'Update')}><FontAwesomeIcon icon={faEdit} /></span>
                    <span className='current-component__delete' onClick={this.deleteNavbarTab.bind(this, navTab.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                </>;

            let navTabBtn = <div className='navbar-tab-opt' key={navTabKey}>
                <div className='tab-title'>
                    {navTab.title}
                </div>

                <div className='current-component__manage'>
                    {currentComponentManagementBtns}
                </div>
            </div>;

            tabButtons.push({ id: navTab.id.toString(), content: navTabBtn });
        }

        let navTabDnD = tabButtons.length > 0 ? <DnDNavbarTabs items={tabButtons} itemOrderUpdate={this.navbarTabOrderUpdate.bind(this)} /> : null;

        if (this.state.reset) {
            navTabDnD = "";
        }

        return (
            <div className='navbar-tabs-management-container component-form'>
                {this.state.popup}

                <div>
                    <div className='tab-management-btns'>
                        <div onClick={this.displayPopup.bind(this, 0, 'Create')} className='add-tab-button'>Add Tab</div>
                    </div>

                    <div className='tabs'>
                        {navTabDnD}
                    </div>
                </div>
            </div>  
        );
    }

    async getComponentData() {
        
        const response = await fetch('Admin/GetNavbarTabs', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        //console.log(data);
        this.setState({
            navbarTabs: data.navbarTabs,
            reset: false,
        });
    }
}

export default NavbarTabsManagement;
