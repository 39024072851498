import React, { Component } from 'react';
import './pageform.css';
import DnDPageOrder from './DnDPageOrder';
import FakeDnDPageOrder from './FakeDnDPageOrder';
import TagsByInput from '../TagsAndChips/TagsByInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { getLoggedInUser } from "../../Authentication";

/* The form component to add/edit pages */
export class PageForm extends Component {
    static displayName = PageForm.name;

    constructor(props) {
        super(props);
        this.state = {
            page: null,
            formType: null,
            mainMenuOptions: null,
            hasSubPages: false,
            childMenuOptions: null,
            subMenuOptions: null,
            hasMenuChildPages: false,
            menuSiblingPages: null,
            resetOrderDnD: false,
            parentPage: null,
            loggedInUser: null,
            isExternal: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    /* Manage page field value changes. */
    handleChange = (e) => {
        let newPage = this.state.page;
        if (e.target.name === "display" || e.target.name === "showOnMenu" || e.target.name == "allowNavigation") {
            newPage[e.target.name] = e.target.checked ? true : false;
        }
        else {
            newPage[e.target.name] = e.target.value;
        }

        //Update cascading menu fields
        if (e.target.name == "menu") {
            newPage['childMenu'] = null;
            newPage['subMenu'] = null;
            if (document.getElementById('childMenu') != null) document.getElementById('childMenu').value = "";
            if (document.getElementById('subMenu') != null) document.getElementById('subMenu').value = "";

            let hasLandingPage = this.hasLandingPageInMenu(e.target.value, null, null);
            if (hasLandingPage) newPage['showOnMenu'] = false;
        }
        else if (e.target.name == "childMenu") {
            newPage['subMenu'] = null;
            if (document.getElementById('subMenu') != null) document.getElementById('subMenu').value = "";

            let hasLandingPage = this.hasLandingPageInMenu(null, e.target.value, null);
            if (hasLandingPage) newPage['showOnMenu'] = false;
        }
        else if (e.target.name == "subMenu") {
            let hasLandingPage = this.hasLandingPageInMenu(null, null, e.target.value);
            if (hasLandingPage) newPage['showOnMenu'] = false;
        }

        if (newPage['showOnMenu'] == false && newPage['friendlyUrl'] != "/") newPage['order'] = null;

        this.setState({ page: newPage });

        if (e.target.name == "menu") {
            let pageId = (e.target.value != null && e.target.value != "") ? e.target.value : 0;
            this.getChildMenuOptions(pageId);
        }
        else if (e.target.name == "childMenu") {
            let pageId = (e.target.value != null && e.target.value != "") ? e.target.value : 0;
            this.getSubMenuOptions(pageId);
        }

        if (e.target.name == "menu" || e.target.name == "childMenu" || e.target.name == "subMenu" || e.target.name === "showOnMenu") {
            this.getMenuSiblingPages();
        }
    }

    /* Update the order of the components on the page */
    pageOrderUpdate = (pageArray) => {
        this.setState({ menuSiblingPages: pageArray });
    }

    async handleSubmit(e) {
        e.preventDefault();
        
        let page = this.state.page;

        //Update values for db
        if (page.menu == "") page.menu = null;
        if (page.childMenu == "") page.childMenu = null;
        if (page.subMenu == "") page.subMenu = null;
        if (this.state.isExternal) page.isExternal = true;

        let url = this.state.formType == "Update" ? "UpdatePage" : "CreatePage";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(page),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            async data => {
                if (!isNaN(data)) {
                    if (this.state.formType == "Create") {
                        //Add version log 
                        let loggedInUser = JSON.parse(await getLoggedInUser());
                        let versionAction = "Created draft V1";
                        const versionResponse = await fetch('Version/AddVersionLog?email=' + loggedInUser.email + "&action=" + versionAction + "&pageId=" + parseInt(data), {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        });
                    }

                    if (page.showOnMenu && this.state.menuSiblingPages.length > 0) {
                        let orderedPageIds = [];
                        for (var i = 0; i < this.state.menuSiblingPages.length; i++) {
                            if (this.state.menuSiblingPages[i].id == "new") {
                                orderedPageIds.push(parseInt(data));
                            } else {
                                orderedPageIds.push(parseInt(this.state.menuSiblingPages[i].id));
                            }
                        }

                        fetch('Admin/UpdatePageOrder', {
                            method: 'POST',
                            body: JSON.stringify({ 'orderedPageIds': orderedPageIds }),
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }).then(
                            pageOrderResponse => pageOrderResponse.json()
                        ).then(
                            pageOrderData => {
                                if (this.state.formType == "Update") {
                                    alert("Page Updated");
                                    this.props.onPageSaved();
                                }
                                else {
                                    window.location = '/PageComponentManagement';
                                }
                            }
                        );
                    }
                    else {
                        if (this.state.formType == "Update") {
                            alert("Page Updated");
                            this.props.onPageSaved();
                        }
                        else {
                            window.location = '/PageComponentManagement';
                        }
                    }
                }
                else {
                    alert(data);
                }
            }
        );
    }

    /* Limit the url key input. */
    validateUrlInput = (e) => {
        if ((e.which !== 32) //Space
                && ((e.which >= 37 && e.which <= 40) //Arrow keys
                || (e.key === "Backspace")
                || (e.key === "-")
                || (e.key >= 0 && e.key <= 9)
                || (e.key >= "a" && e.key <= "z"))) {
            //allow input
        }
        else {
            e.preventDefault();
            return false;
        }
    }

    /* Convert the page name into a url */
    autoGenerateUrl = (e) => {
        if (this.state.page != null && this.state.page.name != null && (this.state.page.friendlyUrl == null || this.state.page.friendlyUrl == "")) {
            let pageName = this.state.page['name'];
            let recommendedUrl = "";

            for (var i = 0; i < pageName.length; i++) {
                let char = pageName.charAt(i);
                if (char.match(/[a-z]/i)) {
                    recommendedUrl += char.toLowerCase();
                }
                else if (char.match(/[0-9]/i)) {
                    recommendedUrl += char;
                }
            }

            let newPage = this.state.page;
            newPage['friendlyUrl'] = recommendedUrl;
            document.getElementById('friendlyUrl').value = recommendedUrl;// Helps to update the display even if the state has changed
            this.setState({ page: newPage });
        }
    }

    async getChildMenuOptions(pageId) {
        const response = await fetch('Admin/GetAllChildMenuOptions?pageId=' + pageId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            childMenuOptions: data
        });
    }

    async getSubMenuOptions(pageId) {
        const response = await fetch('Admin/GetAllSubMenuOptions?pageId=' + pageId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            subMenuOptions: data
        });
    }

    /* Return true if one of the selected menus are considered landing pages. */
    hasLandingPageInMenu(parentMenuId, childMenuId, subMenuId) {
        let hasLandingPage = false;

        // Check parent menu
        if (parentMenuId != null && this.state.mainMenuOptions != null) {
            for (var i = 0; i < this.state.mainMenuOptions.length; i++) {
                if (parentMenuId == this.state.mainMenuOptions[i].id && this.state.mainMenuOptions[i].showOnMenu == false) {
                    hasLandingPage = true;
                }
            }
        }
        
        // Check child menu
        if (childMenuId != null && this.state.childMenuOptions != null) {
            for (var i = 0; i < this.state.childMenuOptions.length; i++) {
                if (childMenuId == this.state.childMenuOptions[i].id && this.state.childMenuOptions[i].showOnMenu == false) {
                    hasLandingPage = true;
                }
            }
        }

        // Check sub menu
        if (subMenuId != null && this.state.subMenuOptions != null) {
            for (var i = 0; i < this.state.subMenuOptions.length; i++) {
                if (subMenuId == this.state.subMenuOptions[i].id && this.state.subMenuOptions[i].showOnMenu == false) {
                    hasLandingPage = true;
                }
            }
        }
        
        return hasLandingPage;
    }

    /* Get the sibling pages from the menu */
    async getMenuSiblingPages() {
        let parentMenuId = this.state.page.menu == "" ? null : this.state.page.menu;
        let childMenuId = this.state.page.childMenu == "" ? null : this.state.page.childMenu;
        let subMenuId = this.state.page.subMenu == "" ? null : this.state.page.subMenu;

        //Get sibling pages
        const response = await fetch('Admin/GetMenuSiblingPages', {
            method: 'POST',
            body: JSON.stringify({ 'parentMenuId': parentMenuId, 'childMenuId': childMenuId, 'subMenuId': subMenuId }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        let menuSiblingPages = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].status != "Deleted") {
                menuSiblingPages.push({ id: data[i].id.toString(), content: data[i].name });
            }
        }

        if (this.state.formType == 'Create') {
            menuSiblingPages.push({ id: 'new', content: 'NEW PAGE' });
        }
        else {
            let isCurrentPageInPages = false;
            for (var i = 0; i < menuSiblingPages.length; i++) {
                if (menuSiblingPages[i].id == this.state.page.id) {
                    isCurrentPageInPages = true;
                    break;
                }
            }
            if (!isCurrentPageInPages) {
                menuSiblingPages.push({ id: this.state.page.id.toString(), content: this.state.page.name });
            }
        }

        //Get the closest parent page
        let parentPage = null;
        if (parentMenuId != null && childMenuId == null && subMenuId == null && this.state.mainMenuOptions != null) {
            for (var i = 0; i < this.state.mainMenuOptions.length; i++) {
                if (this.state.mainMenuOptions[i].id == parentMenuId) parentPage = this.state.mainMenuOptions[i];
            }
        }
        else if (parentMenuId != null && childMenuId != null && subMenuId == null && this.state.childMenuOptions != null) {
            for (var i = 0; i < this.state.childMenuOptions.length; i++) {
                if (this.state.childMenuOptions[i].id == childMenuId) parentPage = this.state.childMenuOptions[i];
            }
        }
        else if (parentMenuId != null && childMenuId != null && subMenuId != null && this.state.subMenuOptions != null) {
            for (var i = 0; i < this.state.subMenuOptions.length; i++) {
                if (this.state.subMenuOptions[i].id == subMenuId) parentPage = this.state.subMenuOptions[i];
            }
        }

        //Update the state
        this.setState({
            resetOrderDnD: true
        });

        this.setState({
            menuSiblingPages: menuSiblingPages,
            parentPage: parentPage,
            resetOrderDnD: false
        });
    }

    render() {
        let name = null;
        let menu = null;
        let display = false;
        let showOnMenu = true;
        let allowNavigation = true;
        let order = null;
        let friendlyUrl = null;
        let menuOptions = [];
        let childMenu = null;
        let childMenuOptions = [];
        let subMenu = null;
        let subMenuOptions = [];
        let parentMenuName;
        let childMenuName;
        let subMenuName;
        let seoTitle;
        let seoMetaDescription;
        let seoMetaKeywords;
        let allowDelete = false;
        let openType;

        let isPageDataSet = false;
        if (this.state.page != null) {
            let page = this.state.page;
            name = page.name;
            menu = page.menu;
            display = page.display;
            showOnMenu = page.showOnMenu;
            allowNavigation = page.allowNavigation;
            order = page.order;
            friendlyUrl = page.friendlyUrl;
            childMenu = page.childMenu;
            subMenu = page.subMenu;
            seoTitle = page.scoTitle;
            seoMetaDescription = page.scoMetaDescription;
            seoMetaKeywords = page.scoMetaKeywords;
            allowDelete = page.allowDelete;
            openType = page.openType;

            isPageDataSet = true;
        }

        let pageNameField = allowDelete ?
            <input name='name' type='text' defaultValue={name} onChange={this.handleChange} onBlur={!this.state.isExternal ? this.autoGenerateUrl : null} required /> :
            <input name='name' type='text' defaultValue={name} onChange={this.handleChange} onBlur={!this.state.isExternal ? this.autoGenerateUrl : null} required disabled />;

        let friendlyUrlField = allowDelete ?
            <input name='friendlyUrl' id='friendlyUrl' type='text' defaultValue={friendlyUrl} onChange={this.handleChange} onKeyDown={this.validateUrlInput} required /> :
            <input name='friendlyUrl' id='friendlyUrl' type='text' defaultValue={friendlyUrl} onChange={this.handleChange} onKeyDown={this.validateUrlInput} required disabled />;

        //Set all parent menu options
        menuOptions.push(<option value="" key="menu0">Root Menu</option>);
        if (this.state.mainMenuOptions != null) {
            for (var i = 0; i < this.state.mainMenuOptions.length; i++) {
                if (this.state.mainMenuOptions[i].id == this.props.pageId) continue;
                if (this.state.mainMenuOptions[i].isExternal) continue;

                let menuKey = "menu" + (i + 1);
                menuOptions.push(<option value={this.state.mainMenuOptions[i].id} key={menuKey}>{this.state.mainMenuOptions[i].name}</option>);

                if (this.state.mainMenuOptions[i].id == menu) parentMenuName = this.state.mainMenuOptions[i].friendlyUrl;
            }
        }

        //Set all child menu options
        childMenuOptions.push(<option value="" key="childOpt0">None</option>);
        if (this.state.childMenuOptions != null) {
            for (var i = 0; i < this.state.childMenuOptions.length; i++) {
                if (this.state.childMenuOptions[i].id == this.props.pageId) continue;
                if (this.state.childMenuOptions[i].isExternal) continue;

                let childMenuKey = "childOpt" + (i + 1);
                childMenuOptions.push(<option value={this.state.childMenuOptions[i].id} key={childMenuKey}>{this.state.childMenuOptions[i].name}</option>);

                if (this.state.childMenuOptions[i].id == childMenu) childMenuName = this.state.childMenuOptions[i].friendlyUrl;
            }
        }

        //Set all sub menu options
        subMenuOptions.push(<option value="" key="subOpt0">None</option>);
        if (this.state.subMenuOptions != null) {
            for (var i = 0; i < this.state.subMenuOptions.length; i++) {
                if (this.state.subMenuOptions[i].id == this.props.pageId) continue;
                if (this.state.subMenuOptions[i].isExternal) continue;

                let subMenuKey = "subOpt" + (i + 1);
                subMenuOptions.push(<option value={this.state.subMenuOptions[i].id} key={subMenuKey}>{this.state.subMenuOptions[i].name}</option>);

                if (this.state.subMenuOptions[i].id == subMenu) subMenuName = this.state.subMenuOptions[i].friendlyUrl;
            }
        }

        let parentMenuDDL = (this.state.hasSubPages || !allowDelete) ?
            <select name='menu' id='menu' value={menu} onChange={this.handleChange} disabled title="Can't change due to child pages">{menuOptions}</select> :
            <select name='menu' id='menu' value={menu} onChange={this.handleChange}>{menuOptions}</select>

        let childMenuDDL = (this.state.hasSubPages || !allowDelete) ?
            <select name='childMenu' id='childMenu' value={childMenu} onChange={this.handleChange} disabled title="Can't change due to child pages">{childMenuOptions}</select> :
            <select name='childMenu' id='childMenu' value={childMenu} onChange={this.handleChange}>{childMenuOptions}</select>;
        let childMenuField;
        if ((menu != null && menu != "" && menu != undefined) && this.state.childMenuOptions != null) {
            childMenuField = <>
                <label>Child Menu: </label>
                {childMenuDDL}
                <div></div>
            </>;
        }

        let subMenuDDL = (this.state.hasSubPages || !allowDelete) ?
            <select name='subMenu' id='subMenu' value={subMenu} onChange={this.handleChange} disabled title="Can't change due to child pages">{subMenuOptions}</select> :
            <select name='subMenu' id='subMenu' value={subMenu} onChange={this.handleChange}>{subMenuOptions}</select>;
        let subMenuField;
        if ((menu != null && menu != "" && menu != undefined) && (childMenu != null && childMenu != "" && childMenu != undefined) && (this.state.childMenuOptions != null && this.state.subMenuOptions != null)) {
            subMenuField = <>
                <label>Sub Menu: </label>
                {subMenuDDL}
                <div></div>
            </>;
        }

        let showOnMenuInput = (this.hasLandingPageInMenu(menu, childMenu, subMenu) || this.state.hasMenuChildPages || !allowDelete) ?
            <input id='showOnMenu' name='showOnMenu' type='checkbox' checked={showOnMenu} onChange={this.handleChange} disabled title="Can't change due to menu/landing page conflict" /> :
            <input id='showOnMenu' name='showOnMenu' type='checkbox' checked={showOnMenu} onChange={this.handleChange} />;

        let allowNavigationCheckbox = (!this.state.hasSubPages || !allowDelete) ?
            <input name='allowNavigation' type='checkbox' checked={allowNavigation} onChange={this.handleChange} disabled title="Only applicable to pages with child pages" /> :
            <input name='allowNavigation' type='checkbox' checked={allowNavigation} onChange={this.handleChange} />;

        let fullUrl = (friendlyUrl == "/") ? window.location.hostname : window.location.hostname + "/";
        if (menu != null && menu != "" && menu != undefined && parentMenuName != undefined) fullUrl += parentMenuName + "/";
        if (childMenu != null && childMenu != "" && childMenu != undefined && childMenuName != undefined) fullUrl += childMenuName + "/";
        if (subMenu != null && subMenu != "" && subMenu != undefined && subMenuName != undefined) fullUrl += subMenuName + "/";
        if (friendlyUrl != null && friendlyUrl != "" && friendlyUrl != undefined) fullUrl += friendlyUrl;

        let dndPageOrder;
        if (this.state.menuSiblingPages != null) {
            let parentPageName = this.state.parentPage != null ? this.state.parentPage.name : "Main";
            let currentPageId = this.state.page != null ? this.state.page.id : 0;

            if (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role != 'Admin') {
                dndPageOrder = showOnMenu ?
                    <FakeDnDPageOrder pages={this.state.menuSiblingPages} parentPageName={parentPageName} currentPageId={currentPageId} /> :
                    <FakeDnDPageOrder pages={[]} parentPageName={parentPageName} currentPageId={currentPageId} />;
            } else {
                dndPageOrder = showOnMenu ?
                    <DnDPageOrder pages={this.state.menuSiblingPages} pageOrderUpdate={this.pageOrderUpdate.bind(this)} parentPageName={parentPageName} currentPageId={currentPageId} /> :
                    <DnDPageOrder pages={[]} pageOrderUpdate={this.pageOrderUpdate.bind(this)} parentPageName={parentPageName} currentPageId={currentPageId} />;//Only applicable to menu pages
            }
            if (this.state.resetOrderDnD) dndPageOrder = "";
        }

        let allowDeleteTag = (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role != 'Admin') ? false : true;
        let seoMetaKeywordsInput = isPageDataSet ?
            <TagsByInput
                tags={seoMetaKeywords}
                tagUpdate={this.handleChange}
                name='scoMetaKeywords'
                isRequired={true}
                allowDuplicates={false}
                inputType='text'
                allowDelete={allowDeleteTag}
            /> : "";

        let seoTitleCharCount = seoTitle != undefined ? seoTitle.length : 0;
        let seoTitleCharCounter = <span className={(seoTitleCharCount < 50 || seoTitleCharCount > 60) ? 'char-counter bad-count' : 'char-counter good-count' }>{seoTitleCharCount}</span>;

        let seoDescriptionCharCount = seoMetaDescription != undefined ? seoMetaDescription.length : 0;
        let seoDescriptionCharCounter = <span className={(seoDescriptionCharCount < 50 || seoDescriptionCharCount > 160) ? 'char-counter bad-count' : 'char-counter good-count'}>{seoDescriptionCharCount}</span>;

        let tempSeoKeywordArray = (seoMetaKeywords != undefined && seoMetaKeywords != null && seoMetaKeywords != "") ? seoMetaKeywords.split(',') : [];
        //console.log(tempSeoKeywordArray);
        let seoKeywordCount = tempSeoKeywordArray.length;
        let seoKeywordCounter = <span className={(seoKeywordCount < 1 || seoKeywordCount > 2) ? 'char-counter bad-count' : 'char-counter good-count'}>{seoKeywordCount}</span>;

        let statusBtn;
        let formBtnBuffer;
        if (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role == 'Admin' && this.state.page != null) {
            if (this.state.page.status == "Deleted") {
                statusBtn = <button onClick={this.restorePage.bind(this)} className='restore-page-btn'>Restore</button>;
            }
            else {
                statusBtn = <button onClick={this.deletePage.bind(this)} className='delete-page-btn'>Delete</button>;
            }
            formBtnBuffer = <div style={{ padding: '0 5px', display: 'inline-block'}}></div>
        }

        let allowNavigationField = !this.state.isExternal ?
            <>
                <label>Allow Navigation: </label>
                <div className='checkbox-wrapper'>
                    {allowNavigationCheckbox}
                </div>
                <div></div>
            </> : "";

        let seoFields = !this.state.isExternal ?
            <>
                <label>SEO Title: </label>
                <input name='scoTitle' type='text' defaultValue={seoTitle} onChange={this.handleChange} required />
                <div className='input-info-container'>
                    {seoTitleCharCounter}
                    <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title='●50-60 characters&#013;●Briefly and accurately describes the content of the page&#013;●Includes keywords sparingly' />
                </div>

                <label>SEO Description: </label>
                <input name='scoMetaDescription' type='text' defaultValue={seoMetaDescription} onChange={this.handleChange} required />
                <div className='input-info-container'>
                    {seoDescriptionCharCounter}
                    <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title='●Between 50–160 characters&#013;●Includes keywords sparingly' />
                </div>

                <label>SEO Keywords: </label>
                {seoMetaKeywordsInput}
                <div className='input-info-container'>
                    {seoKeywordCounter}
                    <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title='●Keyword density: 1-2% &#013;●Between 1-2 words' />
                </div>
            </> : "";

        let openTypeField = this.state.isExternal ?
            <>
                <label>Open Type: </label>
                <select name='openType' value={openType} onChange={this.handleChange} required>
                    <option key='openTypeSelect' value="">--Select--</option>
                    <option key='openTypeNewTab' value="NewTab">New Tab</option>
                    <option key='openTypeCurrentTab' value="CurrentTab">Current Tab</option>
                </select>
                <div></div>
            </> : "";

        let fullUrlField = !this.state.isExternal ?
            <>
                <label>Full URL: </label>
                <p className='plain-text'>{fullUrl}</p>
                <div></div>
            </> : "";

        let formTypeClass = this.state.formType == 'Update' ? 'page-form page-form--update' : 'page-form';
        if (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role != 'Admin') formTypeClass += ' disable-wrapper';
        return (
            <div className={formTypeClass}>
                <form onSubmit={this.handleSubmit}>
                    <div className='grid-wrapper'>
                        <div className='dual-grid'>
                            <div className='dual-grid__1-2 form-title'>
                                <h3>Page Details</h3>
                            </div>

                            <div className='page-fields'>
                                <div className='tri-grid-offset' id='pageSection'>
                                    <label>Page Name: </label>
                                    {pageNameField}
                                    <div></div>
                                    
                                    <label>Friendly URL: </label>
                                    {friendlyUrlField}
                                    <div></div>
                                    
                                    <label>Parent Menu: </label>
                                    {parentMenuDDL}
                                    <div></div>
                                    
                                    {childMenuField}

                                    {subMenuField}

                                    {/*
                                        <label>Private: </label>
                                        <div className='checkbox-wrapper'>
                                            <input name='display' type='checkbox' checked={display} onChange={this.handleChange} />
                                        </div>
                                        <div></div>
                                    */}

                                    <label>Show On Menu: </label>
                                    <div className='checkbox-wrapper'>
                                        {showOnMenuInput}
                                    </div>
                                    <div></div>
                                    
                                    {allowNavigationField}

                                    {openTypeField}
                                   
                                    {seoFields}
                                    
                                    {fullUrlField}
                                </div>
                            </div>
                            <div className='page-order'>
                                {dndPageOrder}
                            </div>

                            <div className='dual-grid__1-2' style={{marginTop: '5px'}}>
                                <button type='submit' className='form-button'>Save</button>
                                {formBtnBuffer}
                                {statusBtn}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let pageId;
        if (this.props.pageId !== undefined) pageId = this.props.pageId;
        
        let formType = this.props.formType != undefined ? this.props.formType : 'Create';
        let isExternal = this.props.isExternal != undefined ? this.props.isExternal : false;

        const response = await fetch('Admin/GetPage?pageId=' + pageId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        const mainMenuResponse = await fetch('Admin/GetAllMainMenuOptions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const mainMenuData = await mainMenuResponse.json();

        //Check if a page has sub pages associated with it
        const hasSubPagesResponse = await fetch('Admin/HasSubPages?pageId=' + pageId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const hasSubPagesData = await hasSubPagesResponse.json();

        // Check if page has at least one associated child page that is in a menu.
        const hasMenuChildPagesResponse = await fetch('Admin/HasMenuChildPages?pageId=' + pageId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const hasMenuChildPagesData = await hasMenuChildPagesResponse.json();

        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        this.setState({
            page: data,
            formType: formType,
            mainMenuOptions: mainMenuData,
            hasSubPages: hasSubPagesData,
            hasMenuChildPages: hasMenuChildPagesData,
            loggedInUser: loggedInUser,
            isExternal: isExternal
        });

        if (this.state.page['menu'] != null && this.state.page['menu'] != "" && this.state.page['menu'] != undefined) {
            this.getChildMenuOptions(this.state.page['menu']);
        }

        if (this.state.page['childMenu'] != null && this.state.page['childMenu'] != "" && this.state.page['childMenu'] != undefined) {
            this.getSubMenuOptions(this.state.page['childMenu']);
        }

        this.getMenuSiblingPages();
    }

    async deletePage(e) {
        e.preventDefault();

        let pageId = this.state.page.id;
        let confirmDelete = window.confirm("Are you sure you want to delete this page?");

        if (confirmDelete) {
            let pId = parseInt(pageId);
            const response = await fetch('Admin/DeletePage?pageId=' + pId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                window.location = '/UpdatePageComponent/' + pageId;
            }
            else {
                alert(data);
            }
        }
    }

    async restorePage(e) {
        e.preventDefault();

        let pageId = this.state.page.id;
        let confirmRestore = window.confirm("Are you sure you want to restore this page?");

        if (confirmRestore) {
            let pId = parseInt(pageId);
            const response = await fetch('Admin/RestorePage?pageId=' + pId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Restored") {
                window.location = '/UpdatePageComponent/' + pageId;
            }
            else {
                alert(data);
            }
        }
    }
}

export default PageForm;
