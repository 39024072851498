import React, { Component } from 'react';
import HtmlEditorField from '../../../HtmlEditorField';

export class FooterSettings extends Component {
    static displayName = FooterSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            footer: null
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newFooter = this.state.footer;
        
        newFooter[e.target.name] = e.target.value;
        
        this.setState({ footer: newFooter });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let footer = this.state.footer;
        console.log(footer);

        let url = footer.id == 0 ? "CreateFooter" : "UpdateFooter";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(footer),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    alert("Saved");
                }
                else {
                    alert("Error");
                }
            }
        );
    }

    render() {
        let contentColumn1 = null;
        let contentColumn2 = null;
        let contentColumn3 = null;
        let contentColumn4 = null;

        if (this.state.footer != null) {
            let footer = this.state.footer;
            contentColumn1 = footer.contentColumn1;
            contentColumn2 = footer.contentColumn2;
            contentColumn3 = footer.contentColumn3;
            contentColumn4 = footer.contentColumn4;
        }

        return (
            <div className='footer-settings-container'>
                <div className='form-title'>
                    <h3>Footer</h3>
                </div>

                <form onSubmit={this.handleSubmit} className='footer-settings-form'>
                    <div className='dual-grid--offset'>
                        <label>Custom Footer Columns: </label>
                        <div className='footer-link-grid'>
                            <HtmlEditorField content={contentColumn1} handleEditorChange={this.handleChange} name='contentColumn1' />
                            <HtmlEditorField content={contentColumn2} handleEditorChange={this.handleChange} name='contentColumn2' />
                            <HtmlEditorField content={contentColumn3} handleEditorChange={this.handleChange} name='contentColumn3' />
                            {/*<HtmlEditorField content={contentColumn4} handleEditorChange={this.handleChange} name='contentColumn4' />*/}
                        </div>

                        <div className='dual-grid__1-2'>
                            <button type='submit' className='form-button'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Admin/GetFooter', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            footer: data,
        });
    }
}

export default FooterSettings;