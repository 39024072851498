import React, { Component } from 'react';
import './formbuildermanagement.css';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';

export class FormBuilderManagement extends Component {
    static displayName = FormBuilderManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            allForms: null,
            filteredForms: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let filteredForms = this.state.filteredForms;

        let tableRows = [];
        if (filteredForms != null) {
            for (var i = 0; i < filteredForms.length; i++) {
                let form = filteredForms[i];

                let row = {
                    editForm: <a href={'/FormBuilder/' + form.id} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>,
                    formName: form.name,
                    //columns: form.columns,
                    formSubmissions: <a href={'/FormBuilderSubmissions/' + form.id} className='view-btn' title='View Submissions'><FontAwesomeIcon icon={faEye} /></a>
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editForm' },
                { label: 'Form Name', field: 'formName', sort: 'asc' },
                //{ label: 'Columns', field: 'columns', sort: 'asc' },
                { label: '', field: 'formSubmissions' },
            ],
            rows: tableRows
        };

        return (
            <div className='form-builder-management-container'>
                <h1 className='page-title'>Form Builder Management</h1>

                <div className='table-container'>
                    <a href='/CreateForm' title='Create a new form'><button className='form-management-button'>Create Form</button></a>

                    <MDBDataTable entries={50} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminForms/GetAllForms', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            allForms: data,
            filteredForms: data
        });
    }
}