/* This file is used to assist in authenticating a user's login. */
import Cookies from 'universal-cookie';
import publicIp from 'public-ip';
let settings = fetch("/");

//let apiRef = "https://6352-43235.el-alt.com";//Live
let apiRef = "https://api.tracsoft.com";//Live
//let apiRef = "https://localhost:44348";//Local
let httpHost = "";

/* Get the company name */
export async function getCompanyName() {
    const response = await fetch('Authentication/GetCompanyName', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });

    const data = await response.json();
    //console.log("CompanyName: ", data);
    return data;
}

/* Call API directly from JS. */
export async function callAPI(methodType, methodUrl, formData, isObj) {
    if (isObj == undefined) isObj = false;
    //Make an object from the form input
    var methodData = {};
    if (formData != null && !isObj) { //FormData is form
        for (var i = 0; i < formData.elements.length; i++) {
            if (formData.elements[i].name != "submit") {
                methodData[formData.elements[i].name] = formData.elements[i].value;
            }
        }

        //Request API call and get response
        const response = await fetch(methodUrl, {
            method: methodType,
            body: JSON.stringify(methodData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        console.info(response);
        const result = await response.text();
        const statusCode = await response.status;

        var responseObj = {
            result: result,
            statusCode: statusCode
        };

        return responseObj;
    }
    else if (formData != null && isObj) {//FormData is object
        //Request API call and get response
        const response = await fetch(methodUrl, {
            method: methodType,
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        console.info(response);
        const result = await response.text();
        const statusCode = await response.status;

        var responseObj = {
            result: result,
            statusCode: statusCode
        };

        return responseObj;
    }
    else {
        //Request API call and get response
        const response = await fetch(methodUrl, {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const result = await response.text();
        const statusCode = await response.status;

        var responseObj = {
            result: result,
            statusCode: statusCode
        };

        return responseObj;
    }
}

/* Authenticate the login info and session of the user. */
export async function authenticateLogin(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/authenticate/", form);
    if (response.statusCode == 200) {
        var isPasswordExp = await isPasswordExpired(response.result);
        if (isPasswordExp == true) {
            updateDisplayForm("login", "changePassword");
            checkPasswordExpiration(response.result);
        }
        else {
            var shouldBypass = await bypassTwoFactor(response.result);
            if (!shouldBypass) {
                updateDisplayForm("login", "twoFactor");
                sendTwoFactorCode(response.result);
            }
        }
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("loginError").innerHTML = error.message;
        console.log(error.message);
    }
}

/* Check if the user's password is expired. */
export async function isPasswordExpired(userId) {
    var response = await callAPI("GET", apiRef + "/api/authentication/changePassword?userId=" + userId, null);
    var statusCode = JSON.parse(response.statusCode);
    return ((statusCode == 200) ? false : true);
}

/* Check if password requires an update. */
export async function checkPasswordExpiration(userId) {
    var response = await callAPI("GET", apiRef + "/api/authentication/changePassword?userId=" + userId, null);
    //console.log("checkPasswordExpiration", response);

    document.getElementById("changePasswordUserId").value = userId;

    if (response.statusCode == 200) {
        var shouldBypass = await bypassTwoFactor(response.result);
        if (!shouldBypass) {
            updateDisplayForm("changePassword", "twoFactor");
            sendTwoFactorCode(userId);
        }
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("changePasswordError").innerHTML = error.message;
    }
}

/* Verify if password has been successfuly updated. */
export async function verifyPasswordUpdate(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/changePassword", form);
    //console.log("verifyPasswordUpdate", response);

    if (response.statusCode == 200) {
        var shouldBypass = await bypassTwoFactor(response.result);
        if (!shouldBypass) {
            updateDisplayForm("changePassword", "twoFactor");
            sendTwoFactorCode(response.result);
        }
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("changePasswordError").innerHTML = error.message;
    }
}

/* Generate and send the two factor code to the user's phone. */
export async function sendTwoFactorCode(userId) {
    var response = await callAPI("GET", apiRef + "/api/authentication/twoFactor?userId=" + userId, null);
    //console.log("sendTwoFactorCode", response);

    document.getElementById("twoFactorUserId").value = userId;

    if (response.statusCode == 200) {

    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("twoFactorError").innerHTML = error.message;
    }
}

/* Resend the two factor code via user input. */
export function resendTwoFactorCode() {
    var userId = document.getElementById("twoFactorUserId").value;
    sendTwoFactorCode(userId);
}

/* Verify the two factor code is correct. */
export async function verifyTwoFactorCode(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/twoFactor", form);
    //console.log("verifyTwoFactorCode", response);

    if (response.statusCode == 200) {
        console.log(response);
        //console.info(response);
        createCookie(response.result);
        window.location.replace(httpHost + "/adminhome");
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("twoFactorError").innerHTML = error.message;
    }
}

/* Set cookie and redirect if the user can bypass the two factor authentication step. */
export async function bypassTwoFactor(userId) {
    const ipAddress = await publicIp.v4();
    var response = await callAPI("GET", apiRef + "/api/authentication/IsIpAllowedBypass?userId=" + userId + "&ipAddress=" + ipAddress, null);

    var statusCode = JSON.parse(response.statusCode);
    if (statusCode == 200) {
        var sessionId = JSON.parse(response.result);
        createCookie(sessionId);
        window.location.replace(httpHost + "/adminhome");
        return true;
    }
    else {
        return false;
    }
    return false;
}

/* Confirm email and send code to reset password. */
export async function forgotPasswordRequest(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/ForgotPasswordRequest", form);

    if (response.statusCode == 200) {
        updateDisplayForm("forgotPasswordRequest", "forgotPasswordChange");
        var email = JSON.parse(response.result);
        document.getElementById("forgotPasswordChangeEmail").value = email;
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("forgotPwRequestError").innerHTML = error.message;
    }
}

/* Verify code and change password. */
export async function forgotPasswordChange(form) {
    console.log(form);
    var response = await callAPI("POST", apiRef + "/api/authentication/ForgotPasswordChange", form);

    if (response.statusCode == 200) {
        window.location.replace("/login");
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("forgotPwChangeError").innerHTML = error.message;
    }
}

/* Confirm the new password matches the confirm password field. */
export function matchPassword(confirmPw) {
    if (confirmPw.value != document.getElementById('newPassword').value) {
        confirmPw.setCustomValidity('Passwords do not match');// Display error message
    } else {
        confirmPw.setCustomValidity("");// Remove error message
    }
}

export function matchNewPassword(confirmPw) {
    if (confirmPw.value != document.getElementById('forgotNewPassword').value) {
        confirmPw.setCustomValidity('Passwords do not match');// Display error message
    } else {
        confirmPw.setCustomValidity("");// Remove error message
    }
}

/* Set the cookie with the session token as the value. */
export function createCookie(sessionToken) {
    var sessionId = (sessionToken.charAt(0) == "\"" && sessionToken.charAt(sessionToken.length - 1) == "\"") ? sessionToken.slice(1, -1) : sessionToken;

    var d = new Date();
    d.setTime(d.getTime() + (7 * (24 * 60 * 60 * 1000)))//90 Days -> (days * milliseconds per day)
    var expires = "expires=" + d.toUTCString();
    //document.cookie = "AuthCookie=" + sessionId + ";" + expires + ";path=/";
    const cookies = new Cookies();
    cookies.set("AuthCookie", sessionId, expires);
}

/* Remove a login cookie */
export function removeLoginCookie() {
    const cookies = new Cookies();
    cookies.remove("AuthCookie");
}

/* Hide/Show next form */
export function updateDisplayForm(hideForm, showForm) {
    for (let i = 0; i < document.getElementsByClassName("error-message").length; i++) {
        document.getElementsByClassName("error-message")[i].innerHTML = "";
    }
    document.getElementsByClassName("error-message")
    document.getElementById(hideForm).children[0].reset();
    document.getElementById(hideForm).setAttribute("style", "display: none");
    document.getElementById(showForm).children[0].reset();
    document.getElementById(showForm).setAttribute("style", "display: block");
}

/* Check if a user is logged in */
export async function isUserLoggedIn() {
    const cookies = new Cookies();
    var sessionToken = cookies.get("AuthCookie");
    var companyName = await getCompanyName();
    
    var response = await callAPI("GET", apiRef + "/api/authentication/getUserBySessionToken?sessionToken=" + sessionToken + "&company=" + companyName, null);
    
    if (response.statusCode != 200 || response.result == null) {
        return false;
    }
    else {
        return true;
    }
}

/* Return the logged in user */
export async function getLoggedInUser() {
    const cookies = new Cookies();
    var sessionToken = cookies.get("AuthCookie");
    var companyName = await getCompanyName();

    var response = await callAPI("GET", apiRef + "/api/authentication/getUserBySessionToken?sessionToken=" + sessionToken + "&company=" + companyName, null);

    if (response.statusCode != 200 || response.result == null) {
        return null;
    }
    else {
        return response.result;
    }
}

/* Return all companies */
export async function getAllCompanies() {
    var response = await callAPI("GET", apiRef + "/api/users/GetAllCompanies", null);

    if (response.statusCode != 200 || response.result == null) {
        return [];
    }
    else {
        return response.result;
    }
}

/* Return all users associated with the company */
export async function getUsersByCompany(company) {
    var response = await callAPI("GET", apiRef + "/api/users/GetUsersByCompany?company=" + company, null);

    if (response.statusCode != 200 || response.result == null) {
        return [];
    }
    else {
        return response.result;
    }
}

/* Return the user associated with the id and company */
export async function getUser(userId, companyName) {
    var response = await callAPI("GET", apiRef + "/api/users/GetUsers?id=" + userId + "&company=" + companyName, null);

    if (response.statusCode != 200 || response.result == null) {
        return null;
    }
    else {
        return response.result;
    }
}

/* Get empty user */
export async function getEmptyUser() {
    var response = await callAPI("GET", apiRef + "/api/users/GetEmptyUser", null);
    if (response.statusCode != 200 || response.result == null) {
        return null;
    }
    else {
        return response.result;
    }
}

/* Delete the user associated with the id and company */
export async function deleteUser(userId, companyName) {
    var response = await callAPI("DELETE", apiRef + "/api/users/DeleteUsers?id=" + userId + "&company=" + companyName, null);

    return response.result;
}

/* Create new user */
export async function createUser(user) {
    var response = await callAPI("POST", apiRef + "/api/users", user, true);

    if (response.statusCode != 201) {
        let modelState = JSON.parse(response.result);
        var errors = "";
        for (const [key, value] of Object.entries(modelState)) {
            errors += key + ": " + value[0] + "\n";
        }
        return errors;
    }
    else {
        return "Created";
    }
}

/* Edit user without password validation */
export async function updateUserWithoutPwValidation(user) {
    var response = await callAPI("PUT", apiRef + "/api/users/AdminEditUser", user, true);
    if (response.statusCode != 204) {
        let modelState = JSON.parse(response.result);
        var errors = "";
        for (const [key, value] of Object.entries(modelState)) {
            errors += key + ": " + value[0] + "\n";
        }
        return errors;
    }
    else {
        return "Updated";
    }
}