import React, { Component } from 'react';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import TagsByInput from '../../TagsAndChips/TagsByInput';
import ImageGalleryField from '../../../ImageGalleryField';

export class CompanySettings extends Component {
    static displayName = CompanySettings.name;
    adminVersionUpdateEmails = "";

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            companySettings: null
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let companySettings = this.state.companySettings;
        companySettings[e.target.name] = e.target.value;
        this.setState({ companySettings: companySettings });
    }

    handleConfigurationChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.adminVersionUpdateEmails = value;
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let adminVersionUpdateEmails = this.adminVersionUpdateEmails;
        let companySettings = this.state.companySettings;

        var url = companySettings.id == 0 ? "CreateCompanySettings" : "UpdateCompanySettings";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify({ CompanySettings: companySettings, AdminVersionUpdateEmails: adminVersionUpdateEmails }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    alert("Saved");
                }
                else {
                    alert("Error");
                }
            }
        );
    }

    render() {
        //Company
        let facebookLink;
        let instagramLink;
        let twitterLink;
        let youtubeLink;
        let linkedinLink;
        let pinterestLink;
        let tumblrLink;
        let companyName;
        let copyrightName;
        let address;
        let telephone;
        let logoUrl;
        if (this.state.companySettings != null) {
            let companySettings = this.state.companySettings;
            facebookLink = companySettings.facebookLink;
            instagramLink = companySettings.instagramLink;
            twitterLink = companySettings.twitterLink;
            youtubeLink = companySettings.youtubeLink;
            linkedinLink = companySettings.linkedinLink;
            pinterestLink = companySettings.pinterestLink;
            tumblrLink = companySettings.tumblrLink;
            companyName = companySettings.companyName;
            copyrightName = companySettings.copyrightName;
            address = companySettings.address;
            telephone = companySettings.telephone;
            logoUrl = companySettings.logoUrl;
        }

        //Configurations
        let adminVersionUpdateEmails = this.adminVersionUpdateEmails;
        let adminVersionEmailsInput = this.state.isReady ?
            <TagsByInput
                tags={adminVersionUpdateEmails}
                tagUpdate={this.handleConfigurationChange}
                name='adminVersionUpdateEmails'
                isRequired={true}
                allowDuplicates={false}
                inputType='email'
            /> : "";

        return(
            <div className='footer-settings-container'>
                <div className='form-title'>
                    <h3>Company Settings</h3>
                </div>

                <form onSubmit={this.handleSubmit} className='company-settings-form'>
                    <div className='dual-grid--offset'>
                        <label>Facebook: </label>
                        <input name='facebookLink' type='text' defaultValue={facebookLink} onChange={this.handleChange} />

                        <label>Instagram: </label>
                        <input name='instagramLink' type='text' defaultValue={instagramLink} onChange={this.handleChange} />

                        <label>Twitter: </label>
                        <input name='twitterLink' type='text' defaultValue={twitterLink} onChange={this.handleChange} />

                        <label>YouTube: </label>
                        <input name='youtubeLink' type='text' defaultValue={youtubeLink} onChange={this.handleChange} />

                        <label>LinkedIn: </label>
                        <input name='linkedinLink' type='text' defaultValue={linkedinLink} onChange={this.handleChange} />

                        <label>Pinterest: </label>
                        <input name='pinterestLink' type='text' defaultValue={pinterestLink} onChange={this.handleChange} />

                        <label>Tumblr: </label>
                        <input name='tumblrLink' type='text' defaultValue={tumblrLink} onChange={this.handleChange} />

                        <label>Company Name: </label>
                        <input name='companyName' type='text' defaultValue={companyName} onChange={this.handleChange} required />

                        <label>Copyright Name: </label>
                        <input name='copyrightName' type='text' defaultValue={copyrightName} onChange={this.handleChange} required />

                        <label>Address: </label>
                        <input name='address' type='text' defaultValue={address} onChange={this.handleChange} required />

                        <label>Telephone: </label>
                        <Cleave name='telephone' options={{ phone: true, phoneRegionCode: 'US' }} value={telephone} onChange={this.handleChange} required />

                        <label>Logo Url: </label>
                        <input name='logoUrl' type='text' defaultValue={logoUrl} onChange={this.handleChange} hidden />
                        <ImageGalleryField name='logoUrl' currentImageUrl={logoUrl} handleImageChange={this.handleChange} />

                        <label>Admin Versioning Emails: </label>
                        {adminVersionEmailsInput}

                        <div className='dual-grid__1-2'>
                            <button type='submit' className='form-button'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Admin/GetCompanySettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.adminVersionUpdateEmails = data.adminVersionUpdateEmails;

        this.setState({
            isReady: true,
            companySettings: data.companySettings
        });
    }
}

export default CompanySettings;
