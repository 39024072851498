import React, { Component } from 'react';
import { getCompanyName, getUsersByCompany, deleteUser } from '../../Authentication';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import './usermanagement.css';

export class UserManagement extends Component {
    static displayName = UserManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            companyName: null,
            users: []
        }
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let companyName = this.state.companyName;
        let users = this.state.users;

        let tableRows = [];
        if (users.length > 0) {
            let usersArray = JSON.parse(users);
            for (var i = 0; i < usersArray.length; i++) {
                let user = usersArray[i];
                let row = {
                    editUser: <a href={'UpdateUser/' + user.id} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    deleteUser: <span className='delete-btn' onClick={this.deleteUser.bind(this, user.id, user.email)} title='Delete'><FontAwesomeIcon icon={faTrashAlt} /></span>
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editUser' },
                { label: 'First Name', field: 'firstName', sort: 'asc' },
                { label: 'Last Name', field: 'lastName', sort: 'asc' },
                { label: 'Email', field: 'email', sort: 'asc' },
                { label: '', field: 'deleteUser' }
            ],
            rows: tableRows
        };

        return (
            <div className='user-management-container'>
                <div className='page-title'>
                    <h1>{companyName} User Management</h1>
                </div>

                <div className='table-container'>
                    <a href='/CreateUser' title='Create a new user'><button className='form-management-button'>Create User</button></a>

                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        const companyName = await getCompanyName();
        const users = await getUsersByCompany(companyName);

        this.setState({
            companyName: companyName,
            users: users
        });
    }

    async deleteUser(userId, userEmail) {
        let confirm = window.confirm("Are you sure you want to delete " + userEmail + "?");
        if (confirm) {
            const deleteUserResponse = await deleteUser(userId, this.state.companyName);
            this.getComponentData();
        }
    }
}

export default UserManagement;