import React, { Component } from 'react';
import { getCompanyName, getUser, getEmptyUser, createUser, updateUserWithoutPwValidation } from '../../Authentication';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import './userform.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export class UserForm extends Component {
    static displayName = UserForm.name;

    constructor(props) {
        super(props);
        this.state = {
            companyName: null,
            userId: props.userId,
            user: null,
            formType: props.formType,
            isNewUserCompany: props.isNewUserCompany != undefined ? props.isNewUserCompany : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let newUser = this.state.user;
        if (name == "phoneNumber") {
            newUser[name] = (value != undefined && value != null && value != "") ? parseInt(value.replace(' ', '').replace(' ', '')) : 0;
        }
        else if (name == "isLockedOut") {
            newUser[name] = e.target.checked ? true : false;
        }
        else {
            newUser[name] = value;
        }

        this.setState({ user: newUser });
    }

    async handleSubmit(e) {
        e.preventDefault();

        let user = this.state.user;
        if (user.company == null || user.company == "" || user.company == undefined) user.company = this.state.companyName;

        if (this.state.formType == 'Create') {
            let result = await createUser(user);

            if (result == "Created") {
                window.location = '/UserManagement';
            }
            else {
                alert(result);
            }
        }
        else {
            let result = await updateUserWithoutPwValidation(user);

            if (result == "Updated") {
                window.location = '/UserManagement';
            }
            else {
                alert(result);
            }
        }
    }

    render() {
        let firstName;
        let lastName;
        let email;
        let password;
        let access;
        let clientNumber;
        let company = this.state.companyName;
        let phoneNumber;
        let role;
        let lastChanged;
        let isLockedOut;
        let invalidAttempts;
        let relatedClients;

        if (this.state.user != null) {
            let user = this.state.user;
            firstName = user.firstName;
            lastName = user.lastName;
            email = user.email;
            password = user.password;
            access = user.access;
            clientNumber = user.clientNumber;
            company = user.company;
            phoneNumber = user.phoneNumber == 0 ? null : user.phoneNumber;
            role = user.role;
            lastChanged = user.lastChanged;
            isLockedOut = user.isLockedOut;
            invalidAttempts = user.invalidAttempts;
            relatedClients = user.relatedClients;
        }

        let passwordField = this.state.formType == 'Create' ?
            <>
                <label>Password: </label>
                <input name='password' type='text' onChange={this.handleChange} required minLength="8" maxLength="20" />
                <div></div>
            </> : "";

        let isLockedOutField = this.state.formType == 'Update' ?
            <>
                <label>Is Locked Out: </label>
                <div className='checkbox-wrapper'>
                    <input name='isLockedOut' type='checkbox' checked={isLockedOut} onChange={this.handleChange} />
                </div>
                <div></div>
            </> : "";

        let invalidAttemptsField = this.state.formType == 'Update' ?
            <>
                <label>Invalid Attempts: </label>
                <input name='invalidAttempts' type='number' defaultValue={invalidAttempts} onChange={this.handleChange} required min={0} max={5} step={1} />
                <div></div>
            </> : "";

        let accessField = this.state.isNewUserCompany || this.state.companyName == "FileNFind" ?
            <>
                <label>Access: </label>
                <input name='access' type='number' defaultValue={access} onChange={this.handleChange} required min={0} step={1} />
                <div></div>
            </> : "";

        let companyField = this.state.isNewUserCompany ?
            <>
                <label>Company: </label>
                <input name='company' type='text' defaultValue={company} onChange={this.handleChange} required />
                <div></div>
            </> : "";

        let clientNumberField = this.state.companyName == "FileNFind" ?
            <>
                <label>Client Number: </label>
                <input name='clientNumber' type='number' defaultValue={clientNumber} onChange={this.handleChange} required min={0} step={1} />
                <div></div>
            </> : "";

        let roleInfoText = "●Admin: Access to the entier site \n" +
            "●Level4: Division in the company under admin with access to nearly the entier site \n"; 
            //"●Level3: Division in the company under Level4 and above Level2 \n" +
            //"●Level2: Division in the company under Level3 and above Level1 \n" +
            //"●Level1: Lowest level division within the company \n" +
            //"●Client: Contractors with restricted access \n" +
            //"●User: Extremely limited access";
        
        let roleField = <>
            <label>Role: </label>
            <select name='role' value={role} onChange={this.handleChange} required>
                <option value=''>--Select--</option>
                <option value='Admin'>Admin</option>
                <option value='Level4'>Level4</option>
                {/*<option value='Level3'>Level3</option>
                <option value='Level2'>Level2</option>
                <option value='Level1'>Level1</option>
                <option value='Client'>Client</option>
                <option value='User'>User</option>*/}
            </select>
            <div className='input-info-container'>
                <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title={roleInfoText} />
            </div>
        </>;

        let relatedClientsField = this.state.companyName == "FileNFind" ?
            <>
                <label>Related Clients: </label>
                <input name='relatedClients' type='text' defaultValue={relatedClients} onChange={this.handleChange} />
                <div></div>
            </> : "";

        return (
            <div className='user-form-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='tri-grid-offset'>
                        <div className='component-title tri-grid__1-3'>
                            <h1>User Management</h1>
                        </div>

                        <label>First Name: </label>
                        <input name='firstName' type='text' defaultValue={firstName} onChange={this.handleChange} required />
                        <div></div>

                        <label>Last Name: </label>
                        <input name='lastName' type='text' defaultValue={lastName} onChange={this.handleChange} required />
                        <div></div>

                        <label>Email: </label>
                        <input name='email' type='email' defaultValue={email} onChange={this.handleChange} required />
                        <div></div>

                        <label>Phone Number: </label>
                        <Cleave name='phoneNumber' value={phoneNumber} options={{ phone: true, phoneRegionCode: 'US' }} pattern="[0-9]{3} [0-9]{3} [0-9]{4}" onChange={this.handleChange} required />
                        <div></div>

                        {passwordField}

                        {accessField}

                        {clientNumberField}

                        {companyField}

                        {roleField}

                        {isLockedOutField}

                        {invalidAttemptsField}

                        {relatedClientsField}

                        <div className='tri-grid__1-3 form-submit'>
                            <button type='submit' className='form-button'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        const companyName = await getCompanyName();
        const userString = this.state.userId > 0 ? await getUser(this.state.userId, companyName) : await getEmptyUser();
        const user = JSON.parse(userString);

        if (user == null) { // An error ocurred or the id and company do not match
            window.location = 'UserManagement';
        }
        else {
            this.setState({
                companyName: companyName,
                user: user
            });
        }
    }
}

export default UserForm;