import React, { Component } from 'react';
import './breadcrumb.css';

export class Breadcrumb extends Component {
    static displayName = Breadcrumb.name;

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbData: []
        }
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        var breadcrumbData = this.state.breadcrumbData;

        return (
            <div className="breadcrumb-wrap">
                <div className="breadcrumb">
                    {breadcrumbData.map((crumb, index) => {
                        return crumb.allowNavigation ?
                            <a href={crumb.link} key={index}>{crumb.name}</a> :
                            <span key={index}>{crumb.name}</span>
                    })}
                </div>
            </div>
        );
    }

    async getComponentData() {
        var url = window.location.href.split('/').slice(3);
        var breadcrumbData = [];
        
        if (url[0] != "PagePreview") {
            for (var i = 0; i < url.length; i++) {
                var link = url.slice(0, i + 1).join('/');
                if (link != "") {
                    const response = await fetch('Components/GetPageFromUrl?pageUrl=' + link, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    });

                    if (response.status == 200) {
                        const page = await response.json();

                        if (page != null) {
                            breadcrumbData.push({ name: page.name.toUpperCase(), link: link, allowNavigation: page.allowNavigation });
                        }
                    }
                    else { //Add parameters to url
                        let lastPage = breadcrumbData.pop();
                        breadcrumbData.push({ name: lastPage.name.toUpperCase(), link: link, allowNavigation: lastPage.allowNavigation });
                    }
                }
            }
        }
        
        this.setState({
            breadcrumbData: breadcrumbData
        });
    }
}

export default Breadcrumb;
