import React, { Component } from 'react';
import './htmleditorcolumns.css';

export class HtmlEditorColumns extends Component {
    static displayName = HtmlEditorColumns.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            htmlEditorColumns: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let contentDisplay;

        if (this.state.htmlEditorColumns != null) {
            let htmlEditorColumns = this.state.htmlEditorColumns;

            let columnContainerClasses = "columns-container columns__" + htmlEditorColumns.format;
            let columnClasses = "single-column";
            if (htmlEditorColumns.borderStyle == "float") columnClasses += " float-column";

            let column1 = <div className={columnClasses} dangerouslySetInnerHTML={{ __html: htmlEditorColumns.column1 }}></div>;
            let column2 = <div className={columnClasses} dangerouslySetInnerHTML={{ __html: htmlEditorColumns.column2 }}></div>;
            let column3 = <div className={columnClasses} dangerouslySetInnerHTML={{ __html: htmlEditorColumns.column3 }}></div>;
            let column4 = <div className={columnClasses} dangerouslySetInnerHTML={{ __html: htmlEditorColumns.column4 }}></div>;

            switch (htmlEditorColumns.format) {
                case "4_4":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                    </div>;
                    break;
                case "4_2-2":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column3}
                    </div>;
                    break;
                case "4_1-3":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column2}
                    </div>;
                    break;
                case "4_3-1":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column4}
                    </div>;
                    break;
                case "4_1-1-2":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column2}
                        {column3}
                    </div>;
                    break;
                case "4_2-1-1":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column3}
                        {column4}
                    </div>;
                    break;
                case "4_1-2-1":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column2}
                        {column4}
                    </div>;
                    break;
                case "4_1-1-1-1":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column2}
                        {column3}
                        {column4}
                    </div>;
                    break;
                case "3_1-1-1":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column2}
                        {column3}
                    </div>
                    break;
                case "3_1-2":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column2}
                    </div>
                    break;
                case "3_2-1":
                    contentDisplay = <div className={columnContainerClasses}>
                        {column1}
                        {column3}
                    </div>
                    break;
                default:
                    break;
            }
        }

        return (
            <div className='html-editor-columns-container component-wrapper'>
                {contentDisplay}
            </div>  
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetHtmlEditorColumns?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            htmlEditorColumns: data
        });
    }
}