import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import adminSettingsService from '../Admin/AdminSettingsService';

export default class AuthorizeUserRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        if (!this.state.ready) {
            return <div></div>;
        }
        else {
            const { component: Component, ...rest } = this.props;

            return <Route {...rest}
                render={(props) => {
                    return <Component {...props} />
                }}
            />
        }
    }

    async getComponentData() {
        this.setState({
            ready: true
        });
    }
}