import React, { Component } from 'react';
import './navbartabs.css';
import HtmlEditorField from '../../../../HtmlEditorField';
import ColumnsIcon from '../../../../ColumnsIcon';

export class NavbarTabForm extends Component {
    static displayName = NavbarTabForm.name;

    constructor(props) {
        super(props);
        this.state = {
            navbarTab: null,
            formType: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newNavbarTab = this.state.navbarTab;
        newNavbarTab[e.target.name] = e.target.value;
        this.setState({ navbarTab: newNavbarTab });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let navbarTab = this.state.navbarTab;

        let url = this.state.formType == "Update" ? "UpdateNavbarTab" : "CreateNavbarTab";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(navbarTab),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let title;
        let format;
        let column1;
        let column2;
        let column3;
        let column4;
        let footer;
        if (this.state.navbarTab != null) {
            let navbarTab = this.state.navbarTab;

            title = navbarTab.title;
            format = navbarTab.format;
            column1 = navbarTab.column1;
            column2 = navbarTab.column2;
            column3 = navbarTab.column3;
            column4 = navbarTab.column4;
            footer = navbarTab.footer;
        }

        // Display according to the format
        let singleEditorWrapperClasses = 'editor-wrapper';
        let doubleEditorWrapperClasses = 'editor-wrapper grid-span-2';
        let htmlEditorColumnsFields;
        switch (format) {
            case "4_1-1-1-1":
                htmlEditorColumnsFields = <>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column2} handleEditorChange={this.handleChange} name='column2' />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column4} handleEditorChange={this.handleChange} name='column4' />
                    </div>
                </>;
                break;
            case "4_2-1-1":
                htmlEditorColumnsFields = <>
                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' />
                    </div>

                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column4} handleEditorChange={this.handleChange} name='column4' />
                    </div>
                </>;
                break;
            case "4_1-1-2":
                htmlEditorColumnsFields = <>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column2} handleEditorChange={this.handleChange} name='column2' />
                    </div>

                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' />
                    </div>
                </>;
                break;
            case "4_2-2":
                htmlEditorColumnsFields = <>
                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' />
                    </div>

                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' />
                    </div>
                </>;
                break;
            default:
                break;
        }

        let editorComponentWrapperClasses = 'dual-grid__1-2 editor-wrapper';
        let htmlEditorFooterField = (format != null && format != "") ?
            <div className={editorComponentWrapperClasses}>
                <HtmlEditorField content={footer} handleEditorChange={this.handleChange} name='footer' />
            </div> : null;
        
        let wrapperClasses = 'navbar-tab-form grid-wrapper component-form';
        return (
            <form id='navbarTabForm' onSubmit={this.handleSubmit.bind(this)} className={wrapperClasses}>
                <div className='tri-grid'>
                    <label>Title:</label>
                    <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required maxLength='20' />
                    <div></div>

                    <label>Format: </label>
                    <div>
                        <div className='inner-quad-grid'>
                            <div>
                                <input type='radio' id='4_1-1-1-1' name='format' value='4_1-1-1-1' checked={format == '4_1-1-1-1'} onChange={this.handleChange} required />
                                <label htmlFor='4_1-1-1-1'><ColumnsIcon format='4_1-1-1-1' /></label>
                            </div>

                            <div>
                                <input type='radio' id='4_2-1-1' name='format' value='4_2-1-1' checked={format == '4_2-1-1'} onChange={this.handleChange} required />
                                <label htmlFor='4_2-1-1'><ColumnsIcon format='4_2-1-1' /></label>
                            </div>

                            <div>
                                <input type='radio' id='4_1-1-2' name='format' value='4_1-1-2' checked={format == '4_1-1-2'} onChange={this.handleChange} required />
                                <label htmlFor='4_1-1-2'><ColumnsIcon format='4_1-1-2' /></label>
                            </div>

                            <div>
                                <input type='radio' id='4_2-2' name='format' value='4_2-2' checked={format == '4_2-2'} onChange={this.handleChange} required />
                                <label htmlFor='4_2-2'><ColumnsIcon format='4_2-2' /></label>
                            </div>
                        </div>
                    </div>
                    <div></div>

                    <div className='tri-grid__1-3'>
                        <div className='inner-quad-grid'>
                            {htmlEditorColumnsFields}
                        </div>
                    </div>

                    <div className='tri-grid__1-3'>
                        {htmlEditorFooterField}
                    </div>

                    <div className='tri-grid__1-3'>
                        <button form='navbarTabForm' type='submit'>Save</button>
                    </div>
                </div>
            </form>  
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetNavbarTab?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            navbarTab: data,
            formType: formType
        });
    }
}

export default NavbarTabForm;