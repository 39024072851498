export class AdminSettingsService {
    /* Check if form settings are initialized */
    async isFormInitialized() {
        const response = await fetch('AdminSettings/HasFormSettingsData', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Return the version limit value */
    async getVersionLimit() {
        const response = await fetch('AdminSettings/GetVersionLimit', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }
}

const adminSettingsService = new AdminSettingsService();
export default adminSettingsService;