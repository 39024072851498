import React, { Component } from 'react';
import './formbuildersinglesubmission.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

export class FormBuilderSingleSubmission extends Component {
    static displayName = FormBuilderSingleSubmission.name;

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            formSubmission: null,
            formSubmissionPairs: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let formName;
        let formFieldsAndValues = [];
        let backUrl = 'FormBuilderManagement';

        if (this.state.form != null) {
            let form = this.state.form;
            formName = form.name;
            backUrl = 'FormBuilderSubmissions/' + form.id;
        }

        if (this.state.formSubmission != null && this.state.formSubmissionPairs != null) {
            let formSubmission = this.state.formSubmission;
            let formSubmissionPairs = this.state.formSubmissionPairs;

            for (var i = 0; i < formSubmissionPairs.length; i++) {
                let subPair = formSubmissionPairs[i];
                let pairField = <>
                    <label>{subPair.fieldName}:</label> <p>{subPair.fieldValue}</p>
                </>;
                formFieldsAndValues.push(pairField);
            }

            var timestamp = new Date(formSubmission.timestamp);
            formFieldsAndValues.push(<><label>Timestamp:</label> <p>{timestamp.toLocaleDateString()} {timestamp.toLocaleTimeString()}</p></>);
        }

        return (
            <div className='form-builder-single-submission-container'>
                <div className='page-title'>
                    <h2>{formName} Submission</h2>
                </div>

                <div className='grid-wrapper'>
                    <div className='dual-grid--offset'>
                        {formFieldsAndValues}

                        <a href={backUrl} className='back-btn' title='Back'><FontAwesomeIcon icon={faArrowCircleLeft} /></a>
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        let formSubmissionId = this.props.match.params.formSubmissionId;
        const response = await fetch('AdminForms/GetSingleFormSubmissionData?formSubmissionId=' + formSubmissionId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            form: data.form,
            formSubmission: data.formSubmissionViewModel.formSubmission,
            formSubmissionPairs: data.formSubmissionViewModel.formSubmissionPairs
        });
    }
}