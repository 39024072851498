import React, { Component } from 'react';
import './navbar.css';
import { ContactInfo } from './ContactInfo'
import { MenuItems } from '../MenuItems/MenuItems';
import { SideMenu } from '../sideMenu/sideMenu';
import Backdrop from '../Backdrop/Backdrop';
import MobileToggleButton from '../NavBar/mobileToggleButton';
import { SocialIcons } from '../SocialIcons/SocialIcons';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import SubNavBar from './SubNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import GoogleTranslate from '../GoogleTranslate';
import NavbarTabs from './NavbarTabs';

export class NavBar extends Component {
    static displayName = NavBar.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            logo: null,
            companyName: null,
            phoneNumber: null,
            menuItems: null,
            showMobileNav: false,
            facebookLink: null,
            instagramLink: null,
            twitterLink: null,
            siteName: null,
            urlPathName: null
        };
    }

    showHide = () => {
        this.setState((prevState) => {
            return { showMobileNav: !prevState.showMobileNav };
        });
    };

    componentDidMount() {
        //Google search script
        //const googleSearchScript = document.createElement("script");
        //googleSearchScript.src = "";
        //googleSearchScript.async = true;
        //document.body.appendChild(googleSearchScript);

        this.getComponentData();
    }

    render() {
        let contactInfo = <ContactInfo phoneNumber={this.state.phoneNumber} />;
        let sideMenu;
        let backdrop;

        if (this.state.showMobileNav) {
            sideMenu = <SideMenu />;
            backdrop = <Backdrop backdropClickHandler={this.showHide} />
        }

        let mobileClasses = 'mobile-nav';
        if (this.state.showMobileNav) {
            mobileClasses = 'open mobile-nav';
        }

        //let socialIcons = <SocialIcons isInNavbar={true} />;
        let socialIcons = "";

        let siteName = this.state.siteName != null ? this.state.siteName : null;

        let loginLink = <a href='/login' className='login-link' ><FontAwesomeIcon icon={faUserCircle} /></a>;

        let logoClasses = this.state.urlPathName == "/" ? "main-logo main-logo__home" : "main-logo";

        let navbarTabs = (this.state.urlPathName != "/" && this.state.urlPathName != null && !this.state.urlPathName.includes("/PagePreview")) ? <NavbarTabs /> : null;

        return (
            <div className='full-width-wrapper'>
                <div className="main-nav-wrap__super">
                    <div className="main-nav-wrap">
                        <div className="main-logo-wrapper">
                            <a href="/" alt="Home Logo"><img src={this.state.logo} className={logoClasses} /></a>
                        </div>

                        <MobileToggleButton hamburgerClickHandler={this.showHide} />

                        <div className='nav-content-container'>
                            <div className="nav-extras">
                                <div className='nav-extras-main'>
                                    {/*
                                    <div className='google-search-bar-container'>
                                        <div className="gcse-searchbox"></div>
                                    </div>
                                    */}
                                </div>

                                <div className='nav-extras-sub'>
                                    <span className='phone'>{contactInfo}</span>

                                    {/*<GoogleTranslate />*/}

                                    {socialIcons}
                                </div>
                            </div>

                            <div className="main-nav">
                                <div className="nav-items">
                                    <MenuItems />
                                </div>
                            </div>

                    
                            <div className='sub-nav' style={{ textAlign: 'right' }}>
                                <SubNavBar pageId={this.props.pageId}/>
                            </div>
                        </div>

                        {backdrop}
                        <div className={mobileClasses}>
                            <SideMenu />
                        </div>
                    </div>

                    <div className='mobile-sub-nav-container'>
                        
                    </div>

                    {navbarTabs}

                    <div className='sub-nav-wrap'>
                    
                    </div>
                    <Breadcrumb />

                    {/*
                    <div id='googleSearchResultsContainer'>
                        <div className="gcse-searchresults"></div>
                    </div>
                    */}
                </div>
            </div>
        );
    }
    async getComponentData() {
        const response = await fetch('components/Navbar?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        //console.log(data);

        let urlPathName = window.location.pathname;

        this.setState({
            logo: data.companySettings.logoUrl,
            companyName: data.companySettings.companyName,
            phoneNumber: data.companySettings.telephone,
            siteName: data.companySettings.companyName,
            urlPathName: urlPathName
        });
    }
}

export default NavBar;