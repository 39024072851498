import React, { Component } from 'react';

let MobileTogglebutton = props => (

    <div className="mobile-nav-button">
        <a href="javascript:void(0);" className="icon" onClick={props.hamburgerClickHandler}>
            <i className="fa fa-bars"></i>
        </a>
    </div>

);

export default MobileTogglebutton;