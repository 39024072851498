import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isUserLoggedIn, getLoggedInUser } from "../Authentication";
import AdminDashboard from '../Admin/AdminDashboard/AdminDashboard';
import adminSettingsService from '../Admin/AdminSettingsService';

export default class AuthorizeAdminRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            authenticated: false,
            isSiteInitialized: false,
            isFormInitialized: false,
            isLayoutCompleted: false,
            loggedInUser: null
        };
    }

    componentDidMount() {
        this.populateAuthentication();
    }

    render() {
        if (!this.state.ready) {
            return <div></div>;
        }
        else {
            const redirectUrl = '/login';
            const { component: Component, ...rest } = this.props;
            const navbarParentUrl = this.props.navbarParentUrl;

            return <Route {...rest}
                render={(props) => {
                    if (this.state.authenticated && this.state.loggedInUser != null) {
                        let path = props.match.path;
                        // Initialization redirects
                        if ((!this.state.isSiteInitialized && path != "/Settings") ||
                            (this.props.requireForm == true && !this.state.isFormInitialized)
                        ) {
                            return <Redirect to={'/Settings'} />
                        }
                        else if (!this.state.isLayoutCompleted && path != "/SiteInformation" && path != "/Settings") {
                            return <Redirect to={'/SiteInformation'} />
                        }
                        else {
                            // User role redirects
                            let role = this.state.loggedInUser.role;
                            let tsAdminUpPaths = [ // Paths only navigable by users with TracSoft Admin role and higher
                                "/TracSoftSettings",
                                "/Settings"
                            ];
                            let adminUpPaths = [ // Paths only navigable by users with Admin role and higher
                                "/CreateUser",
                                "/UpdateUser/:userId",
                                "/UserManagement",
                                "/SiteInformation"
                            ];

                            //console.log(path);

                            if ((role != "Admin" || this.state.loggedInUser.company != "TracSoft") && tsAdminUpPaths.includes(path)) {
                                return <Redirect to={'/AdminHome'} />
                            }
                            else if ((role != "Admin") && adminUpPaths.includes(path)) {
                                return <Redirect to={'/AdminHome'} />
                            }
                            
                            return <AdminDashboard component={<Component {...props} />} navbarParentUrl={navbarParentUrl} /> // Continue path
                        }
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }}
            />
        }
    }

    async populateAuthentication() {
        const authenticated = await isUserLoggedIn();

        const initializationResponse = await fetch('AdminSettings/IsSiteInitialized', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const initializationData = await initializationResponse.json();

        const isFormInitialized = await adminSettingsService.isFormInitialized();

        const isLayoutCompletedResponse = await fetch('Admin/IsLayoutCompleted', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const isLayoutCompleted = await isLayoutCompletedResponse.json();

        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        this.setState({
            authenticated: authenticated,
            isSiteInitialized: initializationData,
            isFormInitialized: isFormInitialized,
            isLayoutCompleted: isLayoutCompleted,
            ready: true,
            loggedInUser: loggedInUser
        });
    }
}