// Component Thumbnails
import NoImageAvailable from './images/NoImageAvailable.png';
import AnnouncementThumbnail from './images/ComponentThumbnails/Announcement.png';
import BannerContainerThumbnail from './images/ComponentThumbnails/Banner.png';
import ImageFormThumbnail from './images/ComponentThumbnails/ImageForm.png';

export class ComponentMap {
    /* Return a component type name for display */
    componentTypeDisplay(componentType) {
        let displayName;
        switch (componentType) {
            case "Announcement":
                displayName = "Announcement";
                break;
            case "Blank":
                displayName = "Blank";
                break;
            case "BannerContainer":
                displayName = "Banner";
                break;
            case "ImageForm":
                displayName = "Form";
                break;
            case "HtmlEditorColumns":
                displayName = "Text Editor";
                break;
            default:
                displayName = componentType;
                break;
        }
        return displayName;
    }

    /* Return the thumbnail that matches with the component */
    getThumbnail(componentType) {
        let thumbnail;
        switch (componentType) {
            case "Announcement":
                thumbnail = AnnouncementThumbnail;
                break;
            case "Blank":
                thumbnail = NoImageAvailable;
                break;
            case "BannerContainer":
                thumbnail = BannerContainerThumbnail;
                break;
            case "ImageForm":
                thumbnail = ImageFormThumbnail;
                break;
            case "HtmlEditorColumns":
                thumbnail = NoImageAvailable;
                break;
            default:
                thumbnail = NoImageAvailable;
                break;
        }
        return thumbnail;
    }
}

const componentMap = new ComponentMap();
export default componentMap;