import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import './corestyle.css';

// Components
import { NavBar } from './NavBar/NavBar';
import { BannerContainer } from './BannerContainer/BannerContainer';
import { Footer } from './Footer/Footer';
import { Announcement } from './Announcement/Announcement';
import { Blank } from './Blank/Blank';
import { SocialIcons } from './SocialIcons/SocialIcons';
import { ImageForm } from './ImageForm/ImageForm';
import { HtmlEditorColumns } from './HtmlEditorColumns/HtmlEditorColumns';

const components = {
    Navbar: NavBar,
    BannerContainer: BannerContainer,
    Footer: Footer,
    Announcement: Announcement,
    Blank: Blank,
    ImageForm: ImageForm,
    SocialIcons: SocialIcons,
    HtmlEditorColumns: HtmlEditorColumns
};

export class Page extends Component {
    static displayName = Page.name;

    constructor(props) {
        super(props);
        this.state = {
            pageContent: null,
            page: null
        };

        this.parentMenu = props.match.params.parentMenu;
        this.childMenu = props.match.params.childMenu;
        this.subMenu = props.match.params.subMenu;
        this.friendlyUrl = props.match.params.friendlyUrl;
    }

    async componentDidMount() {
        this.getPageData();
    }

    generateComponents(data) {
        if (data != null) {
            var elements = [];
            data.forEach((element, i) => {
                const newComponent = components[element.type];
                const newC = React.createElement(newComponent, { componentId: element.componentId, pageId: this.state.page.id, key: i });
                elements.push(newC);

                // Add page header to each page that is not the home page
                if (element.type == "Navbar" && this.state.page.friendlyUrl != "/") {
                    elements.push(<h1 className='page-name-header'>{this.state.page.name}</h1>);
                }
            });
            //console.log(elements);
            return elements;
        }
    }

    render() {
        let pageContentData = this.state.pageContent;
        let page = this.state.page;

        let metaData;
        if (page != null) {
            metaData = <MetaTags>
                <title>{page.scoTitle}</title>
                <meta name="description" content={page.scoMetaDescription} />
                <meta name="keywords" content={page.scoMetaKeywords} />
            </MetaTags>;
        }

        return (
            <div className='dynamic-pages-container'>
                {metaData}
                {this.generateComponents(pageContentData)}
            </div>
        );
    }

    async getPageData() {
        let routeData = {
            parentMenu: this.parentMenu,
            childMenu: this.childMenu,
            subMenu : this.subMenu,
            friendlyUrl: this.friendlyUrl
        };

        const response = await fetch('pages/GetPageByMenuAndPageName', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(routeData)
        });

        const data = await response.json();
        //console.log(data);
        await this.setState({
            pageContent: data.pageComponents,
            page: data.page
        });
    }
}